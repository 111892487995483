import React from "react"
import SEO from "../../components/seo"
import HeroLandingPage from "../../components/pages/shared/hero/landing"
import FeaturesComponent from "../../components/pages/main/landing-features"
import PricingComponent from "../../components/pages/main/detail-pricing"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import EmailChecklist from "../../components/pages/main/email-checklist"
import Banner from "../../components/pages/main/banner"
import Cards from "../../components/pages/main/cards"
import MainPageInfo from "../../components/pages/main/info"
import Discount from "../../components/pages/main/discount"

const CreativeSchoolPage = (props) => {
  return (
    <Layout headerColor={"#6D20D2"} page={"landing_creativeschool"}>
            <SEO title="CRM для творческих школ"
                 description="CRM-система для ворческих школ"
                 url={"https://paraplancrm.ru" + props.location.pathname}
                 ogImage={"https://paraplancrm.ru/images/seo/education.png"}/>
            <HeroLandingPage
              title='CRM-система учета'
              subtitle="для творческих школ"
              idUnderBlock="#features"
              offsetHeader={80}
              list={[
                      '<b>Контроль и аналитика</b> бизнес-процессов.',
                      'Общение в мессенджерах <b>через систему</b>.',                            
                      '<b>Интеграции</b> с интернет-эквайрингом и онлайн-кассой.',
              ]} />
            <FeaturesComponent title='В системе есть все, что вам нужно' subtitle="А не только инструменты для работы с заявками и контроля отдела продаж" />
            <Discount />
            <MainPageInfo
              title='Работа с CRM - это переход на новый уровень'
              subtitle='Параплан CRM создан специально для ведения учета клиентов и продаж в школах искусств и рисования, студиях актерского мастерства и гончарных школах.' />
            <EmailChecklist />
            <Cards />
            <Banner />
            <PricingComponent title='Тарифы' mainPage={true} mobileMenu={false} />
            <ContactFormComponent />
    </Layout>
  )
} 
export default CreativeSchoolPage
